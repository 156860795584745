import './App.css';
import Countdown from 'react-countdown'; // Add the import statement for 'react-countdown'

function App() {
  const countdowns = [
    {
      "date": "2024-05-04T09:10:00",
      "event": "French Speaking"
    },
    {
      "date": "2024-05-09T09:10:00",
      "event": "Ethics"
    },
    {
      "date": "2024-05-10T09:10:00",
      "event": "Paper 1 Bio"
    },
    {
      "date": "2024-05-13T09:10:00",
      "event": "Shakespeare and the 19th century novel"
    },
    {
      "date": "2024-05-14T09:10:00",
      "event": "French Listening"
    },
    {
      "date": "2024-05-14T10:00:00",
      "event": "French Reading"
    }
  ];

  return (
    <div className="App">
      <div className="Content">
        {countdowns.map((countdown, index) => (
          <div key={index}>
            <p>{countdown.event}</p>
            <Countdown date={new Date(countdown.date)} />
          </div>
        ))}
      </div>
    </div>
  );
}

export default App;
